import {
    Box,
  } from "../../../components/StyledComponent";
  import { useEffect, useRef, useState } from "react";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { RoutePattern } from "../../../routes/RoutePattern";
  import SocialIcons from "../../../components/SocialIcons/SocialIcons";
  import BG_DESKTOP from "../../../assets/videos/tour_desktop.mp4";
  import BG_MOBILE from "../../../assets/videos/tour_mobile.mp4";
  import BG_POSTER from "../../../assets/images/Merch Backdrop GIF.gif";
  import styled from "styled-components";
  import { isMobile } from "../../../constants";
  import { ios } from "../../../helpers";
  import useScriptService from "../../Hooks/useScriptService";
  
  const VolumeBtn = styled.button`
    bottom: 7%;
    @media (max-width: 821px) {
      bottom: 14%;
    }
    
    @media (max-width: 1181px) and (max-height: 821px) {
      bottom: 13%;
    }
  `;
  
  const VideoPlayer = styled.video`
  `;
  
  const VideoContainer = styled.div`
    @media (max-width: 820px) {
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      position: absolute;
    }
  `;

  function isSafari() {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
  }
  
  const Tour = () => {
    const videoRef: any = useRef(null);
    const tourDiv: any = useRef(null);
    // useScriptService({ url: "https://widget.bandsintown.com/main.min.js" });
  
    function handleClick(value: string) {
      window.location.href = "/" + value
    }

    useEffect(() => {
      getTours();
    },[]);

    async function getTours() {
      //id_15520338
      //id_15484463
      //id_2963536 
      const response = await fetch('https://rest.bandsintown.com/artists/id_15520338/events/?app_id=e0132e5e7acb064b84155fa2c2460f6a');
      const json = await response.json();
      const tourContainer = tourDiv.current as HTMLDivElement;
      let tours = {};

      // const mtop = Math.ceil(json.length/2);
      // tourContainer.style.marginTop = "-" + mtop + "rem";

      console.log(json.length);

      if (json.length > 0) {
        if (json.length > 5) {
          tourContainer.style.overflowX = 'hidden';
          tourContainer.style.overflowY = 'auto';
        }

        json.map((tour: any, idx: any) => {
          let tDate = new Date(tour.datetime);
          let mons = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          tours[idx] = {
            "date": mons[tDate.getMonth()] + " " + tDate.getDate(),
            "location": tour.venue.location,
            "venue": tour.venue.name,
            "url": tour.offers.url
          }

          const node = document.createElement("div");
          const content = document.createTextNode(mons[tDate.getMonth()] + " " + tDate.getDate() + " " + tour.venue.location + " - " + tour.venue.name);
          node.appendChild(content);
          node.className = "my-3 cursor-pointer glow uppercase";
          node.onclick = () => {
            window.open("https://www.bandsintown.com/t/" + tour.id + "?app_id=e0132e5e7acb064b84155fa2c2460f6a&came_from=267&utm_medium=api&utm_source=public_api&utm_campaign=ticket");
          }
          tourContainer.appendChild(node);
        });
      } else {
        const node = document.createElement("div");
        const content = document.createTextNode("TBA");
        node.appendChild(content);
        node.className = "my-3 glow uppercase text-7xl pt-60 md:pt-72 3xl:pt-[22rem]";
        tourContainer.appendChild(node);
      }
    }
    
    return (
      <Box
        id={RoutePattern.TOUR}
        className={`fixed flex flex-col justify-center items-center -mt-1 xl:mt-0 ${ios ? isSafari() ? "h-[90vh]" : "h-[88vh]" : "h-[93vh]"} xl:h-screen w-full overflow-hidden touch-none`}
      >
        <Box className="z-30 flex flex-col justify-center items-center h-full w-full overflow-hidden touch-none">
          <VideoContainer
            className="h-full w-full align-center"
          >
            <VideoPlayer
              id="video_player"
              ref={videoRef}
              preload="metadata"
              playsInline
              autoPlay
              muted={true}
              loop
              poster={BG_POSTER}
              src={isMobile ? BG_MOBILE : BG_DESKTOP}
              className="h-full w-full object-cover block object-center"
            ></VideoPlayer>
          </VideoContainer>
          {/* mt-[12rem] md:mt-[15rem] 4xl:mt-[20rem] */}
          <Box className="tour-container fixed w-[98%] md:w-4/6 2xl:w-3/4 3xl:w-3/4 h-[68vh] md:h-[76vh] 3xl:h-[78vh] mt-[17rem] md:mt-[15rem] 4xl:mt-[20rem] overflow-hidden touch-none">
            {/* <a 
                className="bit-widget-initializer" 
                data-artist-name="id_15520338" 
                data-display-local-dates="false" 
                data-display-past-dates="false" 
                data-auto-style="false" 
                data-text-color="#FFFFFF" 
                data-link-color="#FFFFFF" 
                data-background-color="rgba(0,0,0,0)" 
                data-display-limit="15" 
                data-link-text-color="#000000" 
                data-display-lineup="false" 
                data-display-play-my-city="false" 
                data-separator-color="rgba(124,124,124,0.25)"
                data-display-start-time="true">

                </a> */}
            {/* {tours.map((tour, idx) => (
              <div className="font-stevie-sans text-[#FFF3E0]">
                {tour.venue.location}
              </div>
            ))} */}
            <div ref={tourDiv} className="tour-info font-stevie-sans text-[#FFF3E0] text-xl md:text-3xl 4xl:text-4xl flex flex-col justify-start items-center -mt-[3rem] 4xl:-mt-[4rem] overflow-hidden"></div>
          </Box>
          <Box className="absolute bottom-5 md:bottom-1">
            <SocialIcons />
          </Box>
        </Box>
      </Box>
    );
  };
  
  export default Tour;
  