import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { data } from "./data";
import styled from "styled-components";

const SocialLink = styled.a``;

interface SocialIconProps {
  pandoraIconWidth?: string;
  iconWidth?: string;
}

export default function SocialIcons({
  iconWidth,
  pandoraIconWidth,
}: SocialIconProps) {
  return (
    <ul className="flex flex-row justify-center items-center text-[#FFF3E0]">
      {data.map((item, idx) => (
        <SocialLink
          key={idx}
          href={item.link}
          rel="noreferrer"
          target="_blank"
          className="hover:text-[#FFF3E0] text-lg md:text-xl m-1 md:m-2 rounded-lg w-6 h-4 md:w-6 md:h-6 flex justify-center items-center"
        >
          {item.icon ? (
            <FontAwesomeIcon icon={item.icon} />
          ) : item.isPandora ? (
            <img
              src={item.img}
              className={pandoraIconWidth + "hidden"}
              style={{
                filter:
                  "invert(89%) sepia(9%) saturate(612%) hue-rotate(333deg) brightness(106%) contrast(103%)",
              }}
            />
          ) : (
            <img
              src={item.img}
              className="h-3 md:h-4"
              style={{
                filter:
                  "invert(89%) sepia(9%) saturate(612%) hue-rotate(333deg) brightness(106%) contrast(103%)",
              }}
            />
          )}
        </SocialLink>
      ))}
    </ul>
  );
}
