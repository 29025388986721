import $ from 'jquery';
import jQuery from 'jquery';

export function generateRandomHeight() {
  function init() {
    const height = window.innerHeight - window.innerHeight * 0.3;
    const randomHeight = ((randomParameter(0.45) * height) / window.innerHeight) * 100;

    return randomHeight;
  }

  const randomHeights = () => {
    const randomTop = randomParameter() * 100;

    return randomTop;
  };

  const randomWidths = () => {
    const randomLeft = randomParameter() * 100;

    return randomLeft;
  };

  const randomParameter = (min?: number, max?: number) => {
    const random = Math.random();

    if (min && random <= min) {
      return min;
    } else if (max && random >= max) {
      return max;
    } else {
      return random;
    }
  };

  return {
    init,
    randomHeights,
    randomWidths,
  };
}

/**
 *
 * generates random heights
 * @param data any[]
 * @returns numbers[]
 */
export function heights(data: any[], divider?: number) {
  const numbers: number[] = [];

  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < data.length; i++) {
    const randomNumber = generateRandomHeight().init();
    numbers.push(divider ? randomNumber / divider : randomNumber);
  }

  return numbers;
}

/**
 * generates random top position
 * @param data
 * @returns
 */
export function tops(data: any[]) {
  const numbers: number[] = [];

  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < data.length; i++) {
    const generator = generateRandomHeight();
    const randomNumber = generator.randomHeights();
    numbers.push(randomNumber);
  }

  return numbers;
}

/**
 * generates random left position
 * @param data
 * @returns
 */
export function lefts(data: any[]) {
  const numbers: number[] = [];

  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < data.length; i++) {
    const generator = generateRandomHeight();
    const randomNumber = generator.randomWidths();

    numbers.push(randomNumber);
  }

  return numbers;
}

export function CSSForHTML() {
  // add css for html
  document.getElementsByTagName('html')[0].classList.add('bg-black');
  document.getElementsByTagName('html')[0].classList.add('overflow-hidden');
  document.getElementsByTagName('body')[0].classList.remove('overflow-x-hidden');
}

export function UnCSSForHTML() {
  // remove css for html
  document.getElementsByTagName('html')[0].classList.remove('overflow-hidden');
  document.getElementsByTagName('body')[0].classList.add('overflow-x-hidden');
}

export function GlitchinImage() {
  (() => {
    const set = (x: any, opts: any) => {
      const _pt = [
        {
          x: 0,
          y: 0,
        },
        {
          x: 0,
          y: 0,
        },
        {
          x: 0,
          y: 0,
        },
      ];
      const rnd1 = [Math.random() + 1, Math.random() + 1, Math.random() + 1];
      const rnd2 = [0, 0, 0];
      let cnt = 0;
      const arr: any = [];
      let loop: any = null;
      let t = null;
      const _h = opts._h;
      const _w = opts._w;
      const img = opts.img;
      let mshov = false;

      x.css({
        position: 'relative',
      });

      for (let i = 0; i < _h; i++) {
        const pos = -i + 'px';
        x.append('<div></div>');
        x.find('div')
          .eq(i)
          .css({
            backgroundImage: 'url(' + img + ')',
            backgroundPosition: '0px ' + pos,
            height: '1px',
            width: _w + 'px',
            position: 'absolute',
            top: i + 'px',
          });
        arr.push(x.find('div').eq(i));
      }

      if (opts.auto) {
        t = setInterval(() => {
          if (mshov) return;
          go();

          setTimeout(pause, (opts.delay / 2) * Math.random());
        }, opts.delay);
      }

      x.mouseover(go);
      x.mouseout(pause);

      function go() {
        mshov = true;
        clearInterval(loop);
        loop = setInterval(run, 30);
      }

      function pause() {
        mshov = false;
        clearInterval(loop);
        loop = null;

        for (let i = 0; i < _h; i++) {
          arr[i].css({
            left: 0,
          });
        }
      }

      function run() {
        let i;
        for (i = 0; i < 3; i++) {
          if (rnd1[i] >= 1) {
            --rnd1[i];
            rnd2[i] = Math.random() / 4 + 0.03;
          }
          rnd1[i] += rnd2[i];
          cnt += (38 - cnt) * 0.25;
          _pt[i].x = Math.ceil(Math.sin(rnd1[i] * Math.PI * 2) * rnd2[i] * cnt * 2);
          _pt[i].y = 0;
        }
        const num = (Math.abs(_pt[0].x) + Math.abs(_pt[1].x) + Math.abs(_pt[2].x) + 8) / 4;

        i = _h;
        while ((i -= 1)) {
          const _off = Math.sin((i / _h) * Math.PI * (Math.random() / 8 + 1)) * 0.8 * num * num;
          arr[i].css({
            left: _off + 'px ',
          });
        }
      }
    };

    (jQuery.fn as any).noisy = function (opts: any) {
      this.each(() => {
        opts = jQuery.extend({}, (jQuery.fn as any).noisy.defs, opts);
        set(jQuery<any>(this), opts);
      });
    };

    (jQuery.fn as any).noisy.defs = {
      _h: 0,
      _w: 0,
      img: '',
      auto: true,
      delay: 4000,
    };
  })();

  /*to see this same image but with its full background use 
    https://s3-us-west-2.amazonaws.com/s.cdpn.io/131045/noise.jpe | I removed the black image bordering.
    Or add any other image of your choice - just be sure to edit the width/height params below. It *should* work with any image.
    */

  $(() => {
    ($('#img') as any).noisy({
      _w: 800,
      _h: 100,
      // img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/131045/ns2.png',
      img: 'https://i.ibb.co/f26hB7X/Life-Of-A-Savage-II-Logo-TP.png',
    });
  });
}

export const ios = (() => {
  const iosQuirkPresent = () => {
    const audio = new Audio();

    audio.volume = 0.5;
    return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAppleDevice = navigator.userAgent.includes('Macintosh');
  const isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
})();
