import {
  setIsBurgerActive,
  setIsScrollableVideoEnded,
  setVideoPopupState,
} from "../../features/Global/Global.slice";

import Store from "../../Store";

export default class GlobalService {
  setIsBurgerActive(value: boolean) {
    Store.dispatch(setIsBurgerActive(value));
  }

  setVideoPopupState(payload: boolean) {
    Store.dispatch(setVideoPopupState(payload));
  }

  setIsScrollableVideoEnded(payload: boolean) {
    Store.dispatch(setIsScrollableVideoEnded(payload));
  }
}
