import {
  Box,
} from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import SocialIcons from "../../../components/SocialIcons/SocialIcons";
import BG_DESKTOP from "../../../assets/videos/videos/video_R50.mp4";
import BG_MOBILE from "../../../assets/videos/videos/mobile_R50.mp4";
import BG_POSTER from "../../../assets/images/Merch Backdrop GIF.gif";
import styled from "styled-components";
import { isMobile } from "../../../constants";
import { ios } from "../../../helpers";
import {
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";

import D_ADHD from "../../../assets/videos/videos/video_ADHD.mp4";
import M_ADHD from "../../../assets/videos/videos/mobile_ADHD.mp4";
import D_B5 from "../../../assets/videos/videos/video_B5.mp4";
import M_B5 from "../../../assets/videos/videos/mobile_B5.mp4";
import D_LexLuger from "../../../assets/videos/videos/video_LexLuger.mp4";
import M_LexLuger from "../../../assets/videos/videos/mobile_LexLuger.mp4";
import D_UziWork from "../../../assets/videos/videos/video_UziWork.mp4";
import M_UziWork from "../../../assets/videos/videos/mobile_UziWork.mp4";
import D_WhatItIs from "../../../assets/videos/videos/video_WhatItIs.mp4";
import M_WhatItIs from "../../../assets/videos/videos/mobile_WhatItIs.mp4";
import D_HiVoltage from "../../../assets/videos/videos/video_HiVoltage.mp4";
import M_HiVoltage from "../../../assets/videos/videos/mobile_HiVoltage.mp4";
import D_R50 from "../../../assets/videos/videos/video_R50.mp4";
import M_R50 from "../../../assets/videos/videos/mobile_R50.mp4";

const VolumeBtn = styled.button`
  bottom: 7%;
  @media (max-width: 821px) {
    bottom: 14%;
  }
  
  @media (max-width: 1181px) and (max-height: 821px) {
    bottom: 13%;
  }
`;

const VideoPlayer = styled.video`
`;

const VideoContainer = styled.div`
  @media (max-width: 820px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
  }
`;

const VideoList = [
  {
    title: "R50",
    videoId: "3f8JXeRTNEg",
    file: (isMobile ? M_R50 : D_R50)
  },
  {
    title: "Hi-Voltage",
    videoId: "Q0ES1MhG1Ao",
    file: (isMobile ? M_HiVoltage : D_HiVoltage)
  },
  {
    title: "ADHD",
    videoId: "pfXubQjav6k",
    file: (isMobile ? M_ADHD : D_ADHD)
  },
  {
    title: "B5",
    videoId: "PPhBdK0hgbk",
    file: (isMobile ? M_B5 : D_B5)
  },
  {
    title: "LexLuger",
    videoId: "IgeGhHlKXPI",
    file: (isMobile ? M_LexLuger : D_LexLuger)
  },
  {
    title: "UziWork",
    videoId: "HMsGAM_qBDU",
    file: (isMobile ? M_UziWork : D_UziWork)
  },
  {
    title: "WhatItIs",
    videoId: "UoWSXXyKH_8",
    file: (isMobile ? M_WhatItIs : D_WhatItIs)
  },
];

function isSafari() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
}

const Videos = () => {
  const videoRef: any = useRef(null);
  // const iframeRef: any = useRef(null);
  const [vidNum, setVidNum] = useState<number>(0);

  function handleClick(value: string) {
    const video = videoRef.current as HTMLVideoElement;
    // const iframe = iframeRef.current as HTMLIFrameElement;
    if (value == 'view') {
      // let vidSrc = "https://www.youtube.com/embed/" + VideoList[vidNum].videoId + "?si=80_Xfbq-1MEdIoCj&amp;controls=0&amp;autoplay=1";
      // iframe.src = vidSrc;
      // iframe.style.display = 'block';
      window.open("https://www.youtube.com/watch?v=" + VideoList[vidNum].videoId);
    } else {
      const vidCount = VideoList.length;
      let currentVid = vidNum;
      // iframe.src = "";
      // iframe.style.display = 'none';
      if (value == 'next') {
        currentVid++;
        if (currentVid >= vidCount) currentVid = 0;
        video.src = VideoList[currentVid].file;
        setVidNum(currentVid);
      } else {
        currentVid--;
        if (currentVid < 0) currentVid = (vidCount - 1);
        video.src = VideoList[currentVid].file;
        setVidNum(currentVid);
      }
    }
    
  }

  useEffect(() => {
    const video = videoRef.current as HTMLVideoElement;

    if (video.muted) {
      video.play();
    }
  }, []);
  
  return (
    <Box
      id={RoutePattern.VIDEOS}
      className={`relative flex flex-col justify-center items-center -mt-1 xl:mt-0 ${ios ? isSafari() ? "h-[90vh]" : "h-[88vh]" : "h-[93vh]"} xl:h-screen w-full overflow-hidden`}
    >
      <Box className="z-30 flex flex-col justify-center items-center h-full w-full">
        <VideoContainer
          className="h-full w-full align-center"
        >
          <VideoPlayer
            id="video_player"
            ref={videoRef}
            preload="metadata"
            playsInline
            autoPlay
            muted
            loop
            poster={BG_POSTER}
            src={isMobile ? BG_MOBILE : BG_DESKTOP}
            className="h-full w-full object-cover block object-center"
          ></VideoPlayer>
        </VideoContainer>
        <Box className="absolute flex flex-col justify-center items-center mt-32 md:mt-0 2xl:mr-[2rem]">
          <Box className="flex flex-row justify-center items-center">
            <div
              className="bg-black/0 w-[10rem] xl:w-[8rem] 3xl:w-[10rem] h-[9rem] xl:h-[8rem] 3xl:h-[10rem] xl:mr-[6rem] 3xl:mr-[8rem] cursor-pointer hidden md:block"
              onClick={() => handleClick('prev')}
            ></div>
            <div
              className="bg-black/0 w-[80vw] xl:w-[38rem] 3xl:w-[48rem] h-[15rem] xl:h-[30rem] 3xl:h-[38rem] mr-2 xl:mr-0 cursor-pointer"
              onClick={() => handleClick('view')}
            >
              {/* <iframe
                src=""
                ref={iframeRef}
                title="YouTube video player" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                className="w-full h-full hidden"
              ></iframe> */}
            </div>
            <div
              className="bg-black/0 w-[10rem] xl:w-[8rem] 3xl:w-[10rem] h-[9rem] xl:h-[8rem] 3xl:h-[10rem] xl:ml-[6rem] 3xl:ml-[8rem] xl:mr-[2rem] 3xl:mr-0 cursor-pointer hidden md:block"
              onClick={() => handleClick('next')}
            ></div>
          </Box>
          <Box className="flex flex-row mt-12 md:hidden">
            <FontAwesomeIcon icon={faCaretLeft as any} className="h-[5rem] m-auto text-[#DA3F37]" onClick={() => handleClick('prev')} />
            <FontAwesomeIcon icon={faCaretRight as any} className="h-[5rem] m-auto text-[#DA3F37]" onClick={() => handleClick('next')} />
          </Box>
        </Box>
        <Box className="absolute bottom-5 md:bottom-1">
          <SocialIcons />
        </Box>
      </Box>
    </Box>
  );
};

export default Videos;
